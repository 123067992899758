import React, { useState, useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { SoundContext } from '../../contexts/SoundContext';

import MutedIcon from '../../../assets/imgs/muted_icon.svg';
import UnmutedIcon from '../../../assets/imgs/unmuted_icon.svg';

const MuteButton = () => {
  const [isMuted, setIsMuted] = useState(true);
  const { toggleMute } = useContext(SoundContext);

  const toggleMuted = () => {
    setIsMuted(!isMuted);
    toggleMute();
  };

  const buttonClassName = isMuted ? 'MutedButton sound_muted' : 'MutedButton';
  const buttonIcon = isMuted ? MutedIcon : UnmutedIcon;

  return (
    <button className={buttonClassName} onClick={toggleMuted}>
      <ReactSVG src={buttonIcon} />
    </button>
  );
};

export default MuteButton;