import React, { useEffect, useState, useContext, useCallback } from 'react';
import ChangeLayout from '../layouts/ChangePostLayout';
import { PropagateLoader } from 'react-spinners';
import { LogOutContext } from '../contexts/LogOutContext';
import useFetchMissingChanges from '../services/useFetchMissingChanges';
import DatabaseClearedContext from '../contexts/DatabaseClearedContext';


const DisplayMissedChanges = ({ loading, data }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const { isDatabaseCleared } = useContext(DatabaseClearedContext);

  useEffect(() => {
    if (!loading && isDatabaseCleared) {
      setShouldRender(true);
    }
  }, [loading, isDatabaseCleared]);

  return (
    <>
      {loading ? (
        <div className='spinner_holder'>
          <div className='spinner'>
            <PropagateLoader color={'#A18600'} size={16} loading={loading} />
          </div>
        </div>
      ) : (
        shouldRender && (
          <>
            <div className='feed__section_title_holder'>
              <div className='section_title_block section_title_block__line'></div>
              <div className='section_title_block section_title_block__title'>
                {data.length > 0 ? (
                  <h3>Recap of alerts you've missed since your last visit.</h3>
                ) : (
                  <h3>You're all up-to-date.</h3>
                )}
              </div>
              <div className='section_title_block section_title_block__line'></div>
            </div>
            {data.length > 0 && <ChangeLayout data={data} />}
          </>
        )
      )}
    </>
  );
};

const DisplayMissedChangesContainer = () => {
  const { LogOut } = useContext(LogOutContext);

  const stableLogOut = useCallback((message) => {
    LogOut(message);
  }, [LogOut]);

  const { loading, data } = useFetchMissingChanges(stableLogOut);

  return <DisplayMissedChanges loading={loading} data={data} />;
};

export default DisplayMissedChangesContainer;