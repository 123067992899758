import React from 'react';
import { ReactSVG } from 'react-svg';

import FlyingBird from '../../../assets/imgs/PanimaFlyingBird.svg';

const BgWaterMark = () => {
  return (
    <div className='large_water_mark_holder'>
        <div className='large_water_mark'>
            <ReactSVG src={FlyingBird} />
        </div>
    </div>
  );
};

export default BgWaterMark;
