import React from 'react';
import { Routes, Route } from "react-router-dom";

//Pages
import Alerts from '../pages/Alerts';
import Sites from '../pages/Sites';
import AddSite from '../pages/sites/AddSite';
import EditSite from '../pages/sites/EditSite';
import ViewSite from '../pages/sites/ViewSite';
import NotFound from '../pages/NotFound';

//Elements
import BgWaterMark from '../elements/imgs/BgWaterMark';

//Functions


const AppLayout = () => {
  return (
    <div className='app_layout_column' id='column_b'>
      <BgWaterMark />
      <div className='app_layout_column__inner'>
        <div id='content__window'>
          <Routes>
            <Route path="/" element={<Alerts />} index />
            <Route path="/sites" element={<Sites />} />
              <Route path="/sites/add" element={<AddSite />} />
              <Route path="/sites/edit/:siteId" element={<EditSite />} />
              <Route path="/sites/view/:siteId" element={<ViewSite />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;