import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import tools from '../../services/tools';
import db from "../../services/useDB";
import ImageWithFallback from '../../elements/imgs/ImageWithFallback';
import NavButton from '../../elements/buttons/NavButton';
import SiteDeleteButton from '../../elements/buttons/SiteDeleteButton';

const ViewSite = () => {
  const { siteId } = useParams(); // Get the siteId parameter from the URL
  const [site, setSite] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSite = async () => {
      const siteObject = await db.sites.get(siteId);
      setSite(siteObject ? siteObject.value : null);
      setIsLoading(false);
    };

    fetchSite();
  }, [siteId]);

  useEffect(() => {
    if (!isLoading && !site) {
      navigate('/sites');
    }
  }, [isLoading, site, navigate]);

  if(isLoading) {
    return (
      <div className='page_loading_holder'>
        <PropagateLoader color={'#A18600'} size={16} loading={isLoading} />;
      </div>
    );
  } else if (site) {
    return (
      <section className='dashboard dashboard_item_page' id='dashboard__sites_view'>
        <div className='dashboard__inner'>
          <div className='dashboard__header'>
            <Breadcrumbs />
            <div className='page_title'>
              <h1><span>Viewing</span>{site.title ? site.title : tools.pretty_url(site.url)}</h1>
            </div>
          </div>
          <div className='site_details'>
            <div className='detail detail_title detail_url'>
              <p>URL</p>
            </div>
            <div className='detail detail_data'>
              <Link target="_blank" to={site.url}>{site.url}</Link>
            </div>
            <div className='detail detail_title'>
              <p>Check Active</p>
            </div>
            <div className='detail detail_data'>
              {site.check ? (
                  <p className='check_active'></p>
              ) : (
                  <p className='check_deactived'></p>
              )}
            </div>
            <div className='detail detail_title'>
              <p>Check Type</p>
            </div>
            <div className='detail detail_data'>
              <p>{site.type}</p>
              {site.type === "custom" && (
                <div className="detail_data_custom_holder">
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Container</p>
                  </div>
                  <div className='sub_detail sub_detail_data'>
                    <p>{site.snapshot.custom_container}</p>
                  </div>
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Element</p>
                  </div>
                  <div className='sub_detail sub_detail_data'>
                    <p>{site.snapshot.custom_element}</p>
                  </div>
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Element Title</p>
                  </div>
                  <div className='sub_detail sub_detail_data'>
                    <p>{site.snapshot.custom_element_title ? site.snapshot.custom_element_title : ""}</p>
                  </div>
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Element Copy</p>
                  </div>
                  <div className='sub_detail sub_detail_data'>
                    <p>{site.snapshot.custom_element_body ? site.snapshot.custom_element_body : ""}</p>
                  </div>
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Element Link</p>
                  </div>
                  <div className='sub_detail sub_detail_data'>
                    <p>{site.snapshot.custom_element_link ? site.snapshot.custom_element_link : "-"}</p>
                  </div>
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Keywords Filter</p>
                  </div>
                  <div className='sub_detail sub_detail_data'>
                    <p>{site.snapshot.custom_element_keywords ? site.snapshot.custom_element_keywords : ""}</p>
                  </div>
                </div>
              )}
            </div>
            <div className='detail detail_title'>
              <p>Check Frequency (Seconds)</p>
            </div>
            <div className='detail detail_data'>
              <p>{site.frequency}</p>
            </div>
            <div className='detail detail_title'>
              <p>Checking Period</p>
            </div>
            <div className='detail detail_data'>
              <div className='checking_period_holder'>
                {site.check_range && (
                  <>
                    {site.check_range.from && (
                      <div className='checking_from_holder'>
                        <p><span>From</span>{tools.pretty_date(site.check_range.from)}</p>
                      </div>
                    )}
                    {site.check_range.until && (
                      <div className='checking_until_holder'>
                        <p><span>Until</span>{tools.pretty_date(site.check_range.until)}</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className='detail detail_title'>
              <p>Added</p>
            </div>
            <div className='detail detail_data'>
              <p>{tools.pretty_date(site.createdAt)}</p>
            </div>
            <div className='detail detail_title'>
              <p>Last Updated</p>
            </div>
            <div className='detail detail_data'>
              <p>{tools.pretty_date(site.updatedAt)}</p>
            </div>
            <div className='detail detail_title'>
              <p>Added By</p>
            </div>
            <div className='detail detail_data detail_lowercase'>
              <p>{site.added_by}</p>
            </div>       
            {site.image && (
              <>
                <div className='detail detail_title'>
                  <p>Image</p>
                </div>
                <div className='detail detail_data'>
                  <ImageWithFallback src={site.image} alt={ site.title + " image" } />
                </div>
              </>
            )}
          </div>
          <div className="site_button_holder">
            <div className="site_button_holder_inner">
              <NavButton to={'/sites/edit/' + siteId} copy="Edit" />
              <SiteDeleteButton id={siteId} />
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export default ViewSite;