import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import MissingWebsite from '../../../assets/imgs/missing_website_icon.svg';

const ImageWithFallback = ({ src, alt }) => {
  const [imageError, setImageError] = useState(false);
  const [proxyError, setProxyError] = useState(false);
  const [proxySrc, setProxySrc] = useState(src);

  useEffect(() => {
    setProxySrc(src);
  }, [src]);

  const handleImageError = () => {
    if (!imageError) {
      setImageError(true);
      setProxySrc(`https://api.allorigins.win/raw?url=${src}`);
    } else if (!proxyError) {
      setProxyError(true);
    }
  };

  return (
    <>
      {imageError && proxyError ? (
        <div className='missing_image'>
          <ReactSVG src={MissingWebsite} alt={alt} />
          <p>Image Missing</p>
        </div>
      ) : (
        <img crossOrigin='anonymous' src={proxySrc} alt={alt} onError={handleImageError} />
      )}
    </>
  );
};

export default ImageWithFallback;