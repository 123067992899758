import React, { useState, useRef } from 'react';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import NavButton from '../../elements/buttons/NavButton';
import SiteSaveButton from '../../elements/buttons/SiteSaveButton';
import tools from '../../services/tools';
import { ReactSVG } from 'react-svg';
import RequiredSymbol from '../../../assets/imgs/required_symbol.svg';
import { useNavigate } from 'react-router-dom';

const AddSite = () => {
  const [formHasUpdated, setFormHasUpdated] = useState(false);
  const [formSaving, setFormSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [saveErrors, setSaveErrors] = useState(null);
  const formRef = useRef();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    url: "",
    type: "general",
    frequency: "10",
    check_range: {
      from: "",
      until: "",
    },
    snapshot: {
      custom_container: "",
      custom_element: "",
      custom_element_title: "",
      custom_element_body: "",
      custom_element_link: "",
      custom_element_keywords: "",
    }
  });

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
  
    setFormSaving(true);
    setSaveErrors(null);

    const { errors, isValid } = tools.validateForm(formData);
    if (!isValid) {
      setFormErrors(errors);
      setFormSaving(false);
    }

    return isValid;
  };

  const handleInputChange = (event, parentKey) => {
    setFormHasUpdated(true);
    const { name, value } = event.target;
  
    if (formErrors[name]) {
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  
    if (parentKey) {
      setFormData({
        ...formData,
        [parentKey]: {
          ...formData[parentKey],
          [name]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleError = (message) => {
    setFormSaving(false);
    setSaveErrors(message);
  }

  const handleAfterSave = () => {
    setFormHasUpdated(false);
    setFormSaving(false);
    navigate('/sites');
  };

  return (
    <section className='dashboard dashboard_item_page' id='dashboard__sites_edit'>
      <div className='dashboard__inner'>
        <div className='dashboard__header'>
          <Breadcrumbs />
          <div className='page_title'>
            <h1>Adding Site</h1>
          </div>
        </div>
        <form className='site_details' onSubmit={handleSubmit} ref={formRef}>
          <div className='detail detail_title detail_url'>
            <p>URL</p>
            <div className='required_symbol_holder'>
              <ReactSVG src={RequiredSymbol} />
            </div>
          </div>
          <div className='detail detail_input'>
            <input type="url" name="url" value={formData.url} onChange={handleInputChange} required autoComplete="off" placeholder='url' disabled={formSaving} />
            {formErrors.url && (
              <div className='field_error_message_holder'>
                <p className="error-message">{formErrors.url}</p>
              </div>
            )}
          </div>
          <div className='detail detail_title'>
            <p>Check Frequency (Seconds)</p>
            <div className='required_symbol_holder'>
              <ReactSVG src={RequiredSymbol} />
            </div>
          </div>
          <div className='detail detail_input'>
            <input type="number" name="frequency" value={formData.frequency} onChange={handleInputChange} required autoComplete="off" disabled={formSaving} />
            {formErrors.frequency && (
              <div className='field_error_message_holder'>
                <p className="error-message">{formErrors.frequency}</p>
              </div>
            )}
          </div>
          <div className='detail detail_title'>
            <p>Check Type</p>
          </div>
          <div className='detail detail_input'>
          <select name="type" value={formData.type} onChange={handleInputChange} autoComplete="off" disabled={formSaving}>
            <option value="general">General</option>
            <option value="custom">Custom</option>
          </select>
            {formData.type === "custom" && (
              <div className="detail_data_custom_holder">
                <div className='sub_detail sub_detail_title'>
                  <p>Custom Container</p>
                  <div className='required_symbol_holder'>
                    <ReactSVG src={RequiredSymbol} />
                  </div>
                </div>
                <div className='sub_detail sub_detail_input'>
                  <input type="text" name="custom_container" value={formData.snapshot.custom_container} onChange={(event) => handleInputChange(event, "snapshot")} required autoComplete="off" placeholder='container class or id' disabled={formSaving} />
                  {formErrors.custom_container && (
                    <div className='field_error_message_holder'>
                      <p className="error-message">{formErrors.custom_container}</p>
                    </div>
                  )}
                </div>
                <div className='sub_detail sub_detail_title'>
                  <p>Custom Element</p>
                  <div className='required_symbol_holder'>
                    <ReactSVG src={RequiredSymbol} />
                  </div>
                </div>
                <div className='sub_detail sub_detail_input'>
                  <input type="text" name="custom_element" value={formData.snapshot.custom_element} onChange={(event) => handleInputChange(event, "snapshot")} required autoComplete="off" placeholder='repeating element class or id' disabled={formSaving} />
                  {formErrors.custom_element && (
                    <div className='field_error_message_holder'>
                      <p className="error-message">{formErrors.custom_element}</p>
                    </div>
                  )}
                </div>
                <div className='sub_detail sub_detail_title'>
                  <p>Custom Element Title</p>
                  <div className='required_symbol_holder'>
                    <ReactSVG src={RequiredSymbol} />
                  </div>
                </div>
                <div className='sub_detail sub_detail_input'>
                  <input type="text" name="custom_element_title" value={formData.snapshot.custom_element_title} onChange={(event) => handleInputChange(event, "snapshot")} required autoComplete="off" placeholder='repeating title class or id' disabled={formSaving} />
                  {formErrors.custom_element_title && (
                    <div className='field_error_message_holder'>
                      <p className="error-message">{formErrors.custom_element_title}</p>
                    </div>
                  )}
                </div>
                <div className='sub_detail sub_detail_title'>
                  <p>Custom Element Copy</p>
                </div>
                <div className='sub_detail sub_detail_input'>
                  <input type="text" name="custom_element_body" value={formData.snapshot.custom_element_body} onChange={(event) => handleInputChange(event, "snapshot")} autoComplete="off" placeholder='repeating copy class or id' disabled={formSaving} />
                </div>
                <div className='sub_detail sub_detail_title'>
                  <p>Custom Element Link</p>
                  <div className='required_symbol_holder'>
                    <ReactSVG src={RequiredSymbol} />
                  </div>
                </div>
                <div className='sub_detail sub_detail_input'>
                  <input type="text" name="custom_element_link" value={formData.snapshot.custom_element_link} onChange={(event) => handleInputChange(event, "snapshot")} required autoComplete="off" placeholder='repeating link class or id' disabled={formSaving} />
                  {formErrors.custom_element_link && (
                    <div className='field_error_message_holder'>
                      <p className="error-message">{formErrors.custom_element_link}</p>
                    </div>
                  )}
                </div>
                <div className='sub_detail sub_detail_title'>
                  <p>Custom Keywords Filter</p>
                </div>
                <div className='sub_detail sub_detail_input'>
                  <input type="text" name="custom_element_keywords" value={formData.snapshot.custom_element_keywords} onChange={(event) => handleInputChange(event, "snapshot")} required autoComplete="off" placeholder="Target keywords separated by a ','" disabled={formSaving} />
                </div>
              </div>
            )}
          </div>
          <div className='detail detail_title'>
            <p>Checking Period (Optional)</p>
          </div>
          <div className='detail detail_data'>
            <div className='checking_period_holder'>
              <div className='checking_holder checking_from_holder'>
                <label htmlFor="from">From</label>
                <input type='datetime-local' name="from" value={formData.check_range.from} onChange={(event) => handleInputChange(event, "check_range")} autoComplete="off" disabled={formSaving} />
              </div>
              <div className='checking_holder checking_until_holder'>
                <label htmlFor="until">Until</label>
                <input type='datetime-local' name="until" value={formData.check_range.until} onChange={(event) => handleInputChange(event, "check_range")} autoComplete="off" disabled={formSaving} />
              </div>
            </div>
          </div>
        </form>
        <div className="site_button_holder">
          {saveErrors && (
            <div className='api_error_message_holder'>
              <p>{saveErrors}</p>
            </div>
          )}
          <div className={saveErrors ? "site_button_holder_inner with_key with_errors" : "site_button_holder_inner with_key"}>
            <div className='keys'>
              <div className='required_key_message_holder'>
                <div className='required_symbol_holder'>
                  <ReactSVG src={RequiredSymbol} />
                </div>
                <p> Field Required. Can't be empty.</p>
              </div>
            </div>
            <div className='buttons'>
              <SiteSaveButton formData={formData} formHasUpdated={formHasUpdated} handleAfterSave={handleAfterSave} handleSubmit={handleSubmit} handleError={handleError} />
              <NavButton to={'/sites'} copy="Cancel" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddSite;