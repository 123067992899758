import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';

import FullScreenIconOpen from '../../../assets/imgs/full_screen_icon__open.svg';
import FullScreenIconClose from '../../../assets/imgs/full_screen_icon__close.svg';

const FullScreenButton = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const hasFullscreenApi =
    document.documentElement.requestFullscreen ||
    document.documentElement.webkitRequestFullscreen ||
    document.documentElement.mozRequestFullScreen ||
    document.documentElement.msRequestFullscreen;

  const isIOs = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const isFullScreenSupported = hasFullscreenApi && !isIOs;

  const toggleFullScreen = () => {
    if (!isFullScreenSupported) return;

    if (document.fullscreenElement) {
      document.exitFullscreen().then(() => {
        setIsFullScreen(false);
      });
    } else {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullScreen(true);
      });
    }
  };

  useEffect(() => {
    if (!isFullScreenSupported) return;

    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsFullScreen(false);
      }
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFullScreenSupported]);

  if (!isFullScreenSupported) {
    return null;
  }

  const buttonClassName = isFullScreen ? 'fullScreenButton full_screen_active' : 'fullScreenButton';
  const buttonIcon = isFullScreen ? FullScreenIconClose : FullScreenIconOpen;

  return (
    <button className={buttonClassName} onClick={toggleFullScreen}>
      <ReactSVG src={buttonIcon} />
    </button>
  );
};

export default FullScreenButton;