import useAxios from "./useAxios";
import db from "./useDB";

class APIError extends Error {
  constructor(message, code) {
    super(message);
    this.name = "APIError";
    this.code = code;
  }
}

async function DeleteSite(delete_id) {
  try {
    const response = await useAxios.put("/sites/delete_site", {
      data: {
        delete_id: delete_id
      }
    });

    if (response.data.success) {

      await db.sites.delete(delete_id);
      await db.latest.where('site_id').equals(delete_id).delete();
      await db.missing.where('site_id').equals(delete_id).delete();

      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    if (err.response.data.error_code === 10) {
      throw new APIError("You were logged out because your session expired. Please log back in to continue.", 10);
    }
  }
  return null;
}

export default DeleteSite;