import { useState, useEffect } from "react";
import db from "./useDB";

const useFetchNewLocalChanges = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchNewChanges = async () => {
      try {
        const storedData = await db.latest.toArray();

        if (storedData.length > 0) {
          const storedDataReturn = await storedData.map(item => item.value);

          setData(storedDataReturn.reverse());
        } else {
            const emptyArray = []; 

            setData(emptyArray);
        }
      } catch (err) {
        
      } finally {
        setLoading(false);
      }
    };

    fetchNewChanges();
  }, []);

  return { loading, data };
};

export default useFetchNewLocalChanges;