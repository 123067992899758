import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import BcBackButton from '../elements/buttons/BreadCrumbBackButton';

const generateBreadcrumbs = (pathname) => {
  const parts = pathname.split('/').filter((part) => part);
  const breadcrumbs = [];

  for (let i = 0; i < parts.length; i++) {
    if (parts[i] === 'view' || parts[i] === 'edit') {
      continue;
    } else {
      const path = `/${parts.slice(0, i + 1).join('/')}`;
      breadcrumbs.push({ name: parts[i], path });
    }
  }

  return breadcrumbs;
};

const Breadcrumbs = () => {
  const location = useLocation();
  const breadcrumbs = generateBreadcrumbs(location.pathname);

  return (
    <div className='breadcrumbs_holder'>
      <BcBackButton breadcrumbs={breadcrumbs} />
      <div className='breadcrumbs'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {breadcrumbs.map((breadcrumb, index) => {
              const isActive = index === breadcrumbs.length - 1;
              return !isActive ? (
                <li key={index} className="breadcrumb-item">
                  <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
                </li>
              ) : (
                <li key={index} className="breadcrumb-item active" aria-current="page">
                  <p>{breadcrumb.name}</p>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Breadcrumbs;