import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import io from 'socket.io-client';
import fetchSpecificChange from '../services/fetchSpecificChange';
import { SoundContext } from './SoundContext';

const SocketContext = createContext(null);
const port = process.env.REACT_APP_PORT || 3000;
const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;

const baseUrl =
  env === 'development'
    ? window.location.protocol + "//" + window.location.hostname + (':' + port)
    : window.location.protocol + "//" + window.location.hostname + (process.env.REACT_APP_PORT ? (':' + process.env.REACT_APP_PORT) : '');

const SocketProvider = ({ children, value }) => {
    const [socket, setSocket] = useState(null);
    const [newChangeData, setNewChangeData] = useState([]);
    const [newPostCounter, setNewPostCounter] = useState(0);
    const [socketConnected, setSocketConnected] = useState(false);
    const [isPageVisible, setIsPageVisible] = useState(document.visibilityState === 'visible');
    const [socketSaidLogOut, setSocketSaidLogOut] = useState(false);
    const { volume, playNotification, stop } = useContext(SoundContext);
    const { isLoggedIn } = value;
    const originalPageTile = "Watcher | Panima Capital";
    const location = useLocation();
    const isRootPath = location.pathname === '/';

    const disconnectSocket = () => {
        if (socket) {
          socket.disconnect();
        }
    };

    const handleVisibilityChange = () => {
        setIsPageVisible(document.visibilityState === 'visible');
    };
    
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []); 

    useEffect(() => {
        if(isLoggedIn) {
            const newSocket = io(baseUrl);
            setSocket(newSocket);
            return () => newSocket.close();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (socket && isLoggedIn) {
          const handleConnect = () => {
            setSocketConnected(true);
          };
          const handleDisconnect = () => {
            setSocketConnected(false);
          };
      
          const handleNewChange = async (change_id) => {
            try {
              const newChange = await fetchSpecificChange(change_id);
      
              if (newChange) {
                setNewChangeData((prevData) => [newChange, ...prevData]);
                setNewPostCounter((prevData) => prevData + 1);
                if (volume) {
                  stop();
                  playNotification();
                }
              }
            } catch (err) {
                if (err.code === 10) {
                    setSocketSaidLogOut(err.message);
                }
            }
          };
      
          socket.on('connect', handleConnect);
          socket.on('disconnect', handleDisconnect);
          socket.on('changeDetected', handleNewChange);
      
          return () => {
            socket.off('connect', handleConnect);
            socket.off('disconnect', handleDisconnect);
            socket.off('changeDetected', handleNewChange);
          };
        }
    }, [socket, playNotification, volume, newChangeData, isLoggedIn, stop, socketSaidLogOut]);
      

    useEffect(() => {
        if (isPageVisible) {
          if (!isRootPath) {
            if (newPostCounter > 0) {
              document.title = `(${newPostCounter}) ${originalPageTile}`;
            } else {
              document.title = originalPageTile;
            }
          } else {
            document.title = originalPageTile;
          }
        } else {
            if (newPostCounter > 0) {
                document.title = `(${newPostCounter}) ${originalPageTile}`;
            } else {
                document.title = originalPageTile;
            } 
        }
    }, [newPostCounter, originalPageTile, isRootPath, isPageVisible]);

    return (
        <SocketContext.Provider value={{socket, socketConnected, newChangeData, setNewChangeData, newPostCounter, setNewPostCounter, disconnectSocket, socketSaidLogOut, setSocketSaidLogOut}}>
            {children}
        </SocketContext.Provider>
    );
};

export { SocketContext, SocketProvider };