import React from 'react';
import DisplayNewChanges from '../services/DisplayNewChanges';
import DisplayMissedChanges from '../services/DisplayMissedChanges';
import LiveIndicator from '../services/LiveIndicator';

const Alerts = () => {
    return (
        <section className='dashboard' id='dashboard__alert'>
            <div className='dashboard__inner'>
                <div className='dashboard__header'>
                    <div className='page_title'>
                        <h1>Latest Alerts</h1>
                    </div>
                    <LiveIndicator />
                </div>
                <div className='dashboard__display_area'>
                    <div className='dashboard__feed'>
                        <div className='dashboard__feed_inner feed__latest_changes'>
                            <DisplayNewChanges />
                        </div>
                        <div className='dashboard__feed_inner feed__missed_changes'>
                            <DisplayMissedChanges />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Alerts;