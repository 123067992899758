import React from 'react';
import { ReactSVG } from 'react-svg';

import ImageWithFallback from '../elements/imgs/ImageWithFallback';
import MissingWebsite from '../../assets/imgs/missing_website_icon.svg';
import tools from '../services/tools';

const changeLayout = ({ data, className }) => {
  return (
    <>
      {data.map((change) => {
        if (change.change_type === 'custom') {
          const actual_change = change.change;
          const link = actual_change.link ? actual_change.link : change.url;
          const title = actual_change.title ? actual_change.title : change.title;
          const date = new Date(change.createdAt);
          const formattedDate = date.toLocaleString('en-GB', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          });

          return (
            <div className={`changePost changePost_custom ${className}`} id={change._id.valueOf()} key={change._id}>
              <a href={link} target="_blank" rel='noreferrer'>
                <div className='changePost__inner'>
                  <div className='Image_Holder changePost__inner_block'>
                    {change.image ? (
                      <ImageWithFallback src={change.image} alt={ change.title + " image" } />
                    ) : (
                      <ReactSVG src={MissingWebsite} />
                    )}
                  </div>
                  <div className='Copy_Holder changePost__inner_block'>
                    <div className='post_copy_header'>
                      <p className='post_domain'>{tools.pretty_domain(change.url)}</p>
                      <p className='post_date'>{formattedDate.split(', ')[1]} {formattedDate.split(', ')[0]}</p>
                    </div>
                    <div className='post_copy'>
                      <h4>{title}</h4>
                      {actual_change.body ? (
                        <p className='body_copy'>{actual_change.body}</p>
                      ) : (
                        null
                      )}
                    </div>
                  </div>
                  <div className='Type_Holder changePost__inner_block'>
                    <p>{change.change_type}</p>
                  </div>
                </div>
              </a>
            </div>
          );
        } else if (change.change_type === 'general') {
          const date = new Date(change.createdAt);
          const formattedDate = date.toLocaleString('en-GB', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          });

          return (
            <div className={`changePost changePost_general ${className}`} id={change._id.valueOf()} key={change._id}>
              <a href={change.url} target="_blank" rel="noreferrer">
                <div className='changePost__inner'>
                  <div className='Image_Holder changePost__inner_block'>
                    {change.image ? (
                      <ImageWithFallback src={change.image} alt={ change.title + " image" } />
                    ) : (
                      <ReactSVG src={MissingWebsite} />
                    )}
                  </div>
                  <div className='Copy_Holder changePost__inner_block'>
                    <div className='post_copy_header'>
                      <p className='post_domain'>{tools.pretty_domain(change.url)}</p>
                      <p className='post_date'>{formattedDate.split(', ')[1]} {formattedDate.split(', ')[0]}</p>
                    </div>
                    <div className='post_copy'>
                      <h4>{change.title}</h4>
                      <p className='body_copy'>Change detected in pages' content.</p>
                    </div>
                  </div>
                  <div className='Type_Holder changePost__inner_block'>
                    <p>{change.change_type}</p>
                  </div>
                </div>
              </a>
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default changeLayout;