import React from 'react';
import { NavLink, useLocation } from "react-router-dom";

import LogoAndName from '../elements/imgs/LogoAndName';
import FullScreenButton from '../elements/buttons/FullScreenButton';
import MutedButton from '../elements/buttons/MuteButton';
import LogoutButton from '../elements/buttons/LogoutButton';
import NewPostCounter from '../services/NewPostCounter';

const Nav = ({ onLogout }) => {
    const location = useLocation();
    const isRootPath = location.pathname === '/';

    const handleLogout = () => {
        if (onLogout) {
            onLogout();
        }
    };

    return (
        <div className='app_layout_column' id='column_a'>
            <div className='app_layout_column__inner'>
                <header>
                    <div className='nav__inner'>
                        <LogoAndName />
                        <nav>
                            <ul>
                                <li>
                                    <NavLink to="/">
                                    {isRootPath ? (
                                        <span>Alerts</span>
                                    ) : (
                                        <span>Alerts<NewPostCounter /></span>
                                    )}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/sites">Sites</NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/users">Users</NavLink>
                                </li> */}
                            </ul>
                        </nav>
                    </div>
                    <div id='tool_button_container'>
                        <MutedButton />
                        <FullScreenButton />
                        <LogoutButton onLogout={handleLogout} />
                    </div>
                </header>
            </div>
        </div>
    );
};

export default Nav;