import Dexie from "dexie";

const db = new Dexie("watcherDB");

db.version(4).stores({
    missing: "id, site_id",
    latest: "id, site_id",
    sites: "id"
});

db.missing.clear();
db.latest.clear();
db.sites.clear();

export default db;