import React, {useState, useContext} from 'react';
import { LogOutContext } from '../../contexts/LogOutContext';
import SaveSite from '../../services/SaveSite';

const removeEmptyKeys = (obj) => {
    const cleanedObj = {};
  
    for (const key in obj) {
      if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          cleanedObj[key] = removeEmptyKeys(obj[key]);
        } else {
          cleanedObj[key] = obj[key];
        }
      }
    }
  
    return cleanedObj;
};  

const SiteSaveButton = ({ id=null, formData, formHasUpdated, handleAfterSave, handleSubmit, handleError }) => {
    const { LogOut } = useContext(LogOutContext);
    const [saving, setSaving] = useState(false);
    
    const handleSaveClick = async () => {
        const isValid = handleSubmit();
        setSaving(true);
      
        if (isValid && formHasUpdated) {
            try {
                formData = removeEmptyKeys(formData);

                const dateFields = ["from", "until"];
                dateFields.forEach((field) => {
                    const dateValue = formData.check_range[field];
                    if (dateValue && dateValue !== "" && process.env.NODE_ENV !== 'development') {
                        const utcDateString = dateValue + "Z";
                        const dateObj = new Date(utcDateString);
                        const timezoneOffsetInMinutes = dateObj.getTimezoneOffset();
                        const timezoneOffsetInMilliseconds = timezoneOffsetInMinutes * 60 * 1000;
                        const adjustedTimestamp = dateObj.getTime() + timezoneOffsetInMilliseconds;
                        const adjustedDateObj = new Date(adjustedTimestamp);
                        const adjustedDateString = adjustedDateObj.toISOString().slice(0, -8);
                        formData.check_range[field] = adjustedDateString;
                    }
                });

                const saveSite = await SaveSite(id, formData);
                
                if (saveSite) {
                    setSaving(false);
                    handleAfterSave();
                }
            } catch (err) {
                if (err.code === 10) {
                    setSaving(false);
                    LogOut(err.message);
                } else {
                    setSaving(false);
                    handleError(err.message);
                }
            }
        } else {
          setSaving(false);
        }
    };

    return (
        <div className='btn btn_save_site'>
            <button className="save_button" onClick={handleSaveClick} disabled={saving} type="submit">
                <div className='button_inner'>
                    <p>{saving ? "Saving..." : "Save"}</p>
                </div>
            </button>
        </div>
    )
}

export default SiteSaveButton;