import React from 'react';
import { Link } from 'react-router-dom';

const NavigationButton = ({ to, copy }) => {
    return (
        <div className='btn btn_nav'>
            <Link to={to} >
                <div className='button_inner'>
                    <p>{copy}</p>
                </div>
            </Link>
        </div>
    )
}

export default NavigationButton;