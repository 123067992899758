import React from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import BackArrow from '../../../assets/imgs/back_arrow_button.svg';

const BackButton = ({ breadcrumbs }) => {
  if (breadcrumbs.length < 2) {
    return null;
  }

  const parentBreadcrumb = breadcrumbs[breadcrumbs.length - 2];

  return (
    <div className='btn bc_back_btn'>
        <Link to={parentBreadcrumb.path}>
            <ReactSVG src={BackArrow} />
        </Link>
    </div>
  );
};

export default BackButton;