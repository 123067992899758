import { useState, useEffect } from "react";
import useAxios from './useAxios';
import db from "./useDB";

const useFetchAllSites = (LogOut) => {
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState([]);

  useEffect(() => {
    const fetchAllSites = async () => {
      const initLoad = sessionStorage.getItem('initSitesLoad');

      try {
        const storedSites = await db.sites.toArray();

        if (storedSites.length > 0 || initLoad === 'loaded') {
          const storedSitesReturn = await storedSites.map(site => site.value);

          setSites(storedSitesReturn);
        } else {
          const response = await useAxios.get("/sites/get_sites");
          if (response.data && Array.isArray(response.data) && response.data.length > 0) {
            const changesWithIdAsKey = response.data.map((site) => ({
              id: site._id.valueOf(),
              value: site,
            }));
              
            await db.sites.bulkPut(changesWithIdAsKey);

            setSites(response.data);
          }
          sessionStorage.setItem('initSitesLoad', 'loaded');
        }
      } catch (err) {
        if(err.response.data.error_code) {
          if(err.response.data.error_code === 10) {
            LogOut("You were logged out because your session expired. Please log back in to continue.");
          }
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAllSites();
  }, [LogOut]);

  return { loading, sites };
};

export default useFetchAllSites;