import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import Breadcrumbs from '../../layouts/Breadcrumbs';
import tools from '../../services/tools';
import db from "../../services/useDB";
import ImageWithFallback from '../../elements/imgs/ImageWithFallback';
import NavButton from '../../elements/buttons/NavButton';
import SiteDeleteButton from '../../elements/buttons/SiteDeleteButton';
import SiteSaveButton from '../../elements/buttons/SiteSaveButton';
import { ReactSVG } from 'react-svg';
import RequiredSymbol from '../../../assets/imgs/required_symbol.svg';

const EditSite = () => {
  const { siteId } = useParams();
  const [site, setSite] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [formHasUpdated, setFormHasUpdated] = useState(false);
  const [formSaving, setFormSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [saveErrors, setSaveErrors] = useState(null);
  const formRef = useRef();

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
  
    setFormSaving(true);

    const { errors, isValid } = tools.validateForm(formData);
    if (!isValid) {
      setFormErrors(errors);
      setFormSaving(false);
    }

    return isValid;
  };

  const handleInputChange = (event, parentKey) => {
    setFormHasUpdated(true);
    const { name, value } = event.target;
  
    if (formErrors[name]) {
      setFormErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  
    if (parentKey) {
      setFormData({
        ...formData,
        [parentKey]: {
          ...formData[parentKey],
          [name]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleAfterSave = () => {
    setFormHasUpdated(false);
    setFormSaving(false);
    navigate('/sites/view/' + siteId);
  };

  const handleError = (message) => {
    setFormSaving(false);
    setSaveErrors(message);
  }

  const handleToggleCheck = () => {
    if(!formSaving) {
      setFormHasUpdated(true);
      setFormData({ ...formData, check: !formData.check });
    }
  };

  useEffect(() => {
    const fetchSite = async () => {
      const siteObject = await db.sites.get(siteId);
      setSite(siteObject ? siteObject.value : null);
      setIsLoading(false);
    };

    fetchSite();
  }, [siteId]);

  useEffect(() => {
    if(site) {
      setFormData({
        ...site,
        check_range: {
          from: site.check_range?.from || "",
          until: site.check_range?.until || "",
        },
      });
    }
  }, [site]);

  useEffect(() => {
    if (!isLoading && !site) {
      navigate('/sites');
    }
  }, [isLoading, site, navigate]);

  if(isLoading) {
    return (
      <div className='page_loading_holder'>
        <PropagateLoader color={'#A18600'} size={16} loading={isLoading} />;
      </div>
    );
  } else if (formData) {
    return (
      <section className='dashboard dashboard_item_page' id='dashboard__sites_edit'>
        <div className='dashboard__inner'>
          <div className='dashboard__header'>
            <Breadcrumbs />
            <div className='page_title'>
              <h1><span>Editing</span>{site.title ? site.title : tools.pretty_url(site.url)}</h1>
            </div>
          </div>
          <form className='site_details' onSubmit={handleSubmit} ref={formRef}>
            <div className='detail detail_title detail_url'>
              <p>URL</p>
              <div className='required_symbol_holder'>
                <ReactSVG src={RequiredSymbol} />
              </div>
            </div>
            <div className='detail detail_input'>
              <input type="url" name="url" value={formData.url} onChange={handleInputChange} required autoComplete="off" placeholder='url' disabled={formSaving} />
              {formErrors.url && (
                <div className='field_error_message_holder'>
                  <p className="error-message">{formErrors.url}</p>
                </div>
              )}
            </div>
            <div className='detail detail_title'>
              <p>Check Active</p>
            </div>
            <div className='detail detail_data'>
              <div className={formData.check ? "check_active check_input" : "check_deactived check_input"} onClick={handleToggleCheck}></div>
            </div>
            <div className='detail detail_title'>
              <p>Check Type</p>
            </div>
            <div className='detail detail_input'>
            <select name="type" value={formData.type} onChange={handleInputChange} autoComplete="off" disabled={formSaving}>
              <option value="general">General</option>
              <option value="custom">Custom</option>
            </select>
              {formData.type === "custom" && (
                <div className="detail_data_custom_holder custom_holder_edit">
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Container</p>
                    <div className='required_symbol_holder'>
                      <ReactSVG src={RequiredSymbol} />
                    </div>
                  </div>
                  <div className='sub_detail sub_detail_input'>
                    <input type="text" name="custom_container" value={formData.snapshot.custom_container} onChange={(event) => handleInputChange(event, "snapshot")} required autoComplete="off" placeholder='container class or id' disabled={formSaving} />
                    {formErrors.custom_container && <p className="error-message">{formErrors.custom_container}</p>}
                  </div>
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Element</p>
                    <div className='required_symbol_holder'>
                      <ReactSVG src={RequiredSymbol} />
                    </div>
                  </div>
                  <div className='sub_detail sub_detail_input'>
                    <input type="text" name="custom_element" value={formData.snapshot.custom_element} onChange={(event) => handleInputChange(event, "snapshot")} required autoComplete="off" placeholder='repeating element class or id' disabled={formSaving} />
                    {formErrors.custom_element && (
                      <div className='field_error_message_holder'>
                        <p className="error-message">{formErrors.custom_element}</p>
                      </div>
                    )}
                  </div>
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Element Title</p>
                    <div className='required_symbol_holder'>
                      <ReactSVG src={RequiredSymbol} />
                    </div>
                  </div>
                  <div className='sub_detail sub_detail_input'>
                    <input type="text" name="custom_element_title" value={formData.snapshot.custom_element_title} onChange={(event) => handleInputChange(event, "snapshot")} required autoComplete="off" placeholder='repeating title class or id' disabled={formSaving} />
                    {formErrors.custom_element_title && (
                      <div className='field_error_message_holder'>
                        <p className="error-message">{formErrors.custom_element_title}</p>
                      </div>
                    )}
                  </div>
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Element Copy</p>
                  </div>
                  <div className='sub_detail sub_detail_input'>
                    <input type="text" name="custom_element_body" value={formData.snapshot.custom_element_body} onChange={(event) => handleInputChange(event, "snapshot")} autoComplete="off" placeholder='repeating copy class or id' disabled={formSaving} />
                  </div>
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Element Link</p>
                    <div className='required_symbol_holder'>
                      <ReactSVG src={RequiredSymbol} />
                    </div>
                  </div>
                  <div className='sub_detail sub_detail_input'>
                    <input type="text" name="custom_element_link" value={formData.snapshot.custom_element_link} onChange={(event) => handleInputChange(event, "snapshot")} required autoComplete="off" placeholder='repeating link class or id' disabled={formSaving} />
                    {formErrors.custom_element_link && (
                      <div className='field_error_message_holder'>
                        <p className="error-message">{formErrors.custom_element_link}</p>
                      </div>
                    )}
                  </div>
                  <div className='sub_detail sub_detail_title'>
                    <p>Custom Keywords Filter</p>
                  </div>
                  <div className='sub_detail sub_detail_input'>
                    <input type="text" name="custom_element_keywords" value={formData.snapshot.custom_element_keywords} onChange={(event) => handleInputChange(event, "snapshot")} required autoComplete="off" placeholder="Target keywords separated by a ','" disabled={formSaving} />
                  </div>
                </div>
              )}
            </div>
            <div className='detail detail_title'>
              <p>Check Frequency (Seconds)</p>
              <div className='required_symbol_holder'>
                <ReactSVG src={RequiredSymbol} />
              </div>
            </div>
            <div className='detail detail_input'>
              <input type="number" name="frequency" value={formData.frequency} onChange={handleInputChange} required autoComplete="off" disabled={formSaving} />
              {formErrors.frequency && (
                <div className='field_error_message_holder'>
                  <p className="error-message">{formErrors.frequency}</p>
                </div>
              )}
            </div>
            <div className='detail detail_title'>
              <p>Checking Period (Optional)</p>
            </div>
            <div className='detail detail_data'>
              <div className='checking_period_holder'>
                <div className='checking_holder checking_from_holder'>
                  <label htmlFor="from">From</label>
                  <input type='datetime-local' name="from" value={tools.formatDateToInput(formData.check_range.from)} onChange={(event) => handleInputChange(event, "check_range")} autoComplete="off" disabled={formSaving} />
                </div>
                <div className='checking_holder checking_until_holder'>
                  <label htmlFor="until">Until</label>
                  <input type='datetime-local' name="until" value={tools.formatDateToInput(formData.check_range.until)} onChange={(event) => handleInputChange(event, "check_range")} autoComplete="off" disabled={formSaving} />
                </div>
              </div>
            </div>
            <div className='detail detail_title uneditable'>
              <p>Added</p>
            </div>
            <div className='detail detail_data uneditable'>
              <p>{tools.pretty_date(site.createdAt)}</p>
            </div>
            <div className='detail detail_title uneditable'>
              <p>Last Updated</p>
            </div>
            <div className='detail detail_data uneditable'>
              <p>{tools.pretty_date(site.updatedAt)}</p>
            </div>
            <div className='detail detail_title uneditable'>
              <p>Added By</p>
            </div>
            <div className='detail detail_data uneditable'>
              <p>{site.added_by}</p>
            </div>        
            {site.image && (
              <>
                <div className='detail detail_title uneditable'>
                  <p>Image</p>
                </div>
                <div className='detail detail_data uneditable'>
                  <ImageWithFallback src={site.image} alt={ site.title + " image" } />
                </div>
              </>
            )}
          </form>
          <div className="site_button_holder">
            {saveErrors && (
              <div className='api_error_message_holder'>
                <p>{saveErrors}</p>
              </div>
            )}
            <div className={saveErrors ? "site_button_holder_inner with_key with_errors" : "site_button_holder_inner with_key"}>
              <div className='keys'>
                <div className='required_key_message_holder'>
                  <div className='required_symbol_holder'>
                    <ReactSVG src={RequiredSymbol} />
                  </div>
                  <p> Field Required. Can't be empty.</p>
                </div>
                <p>Greyed out fields cannot be edited.</p>
              </div>
              <div className='buttons'>
                <SiteSaveButton id={siteId} formData={formData} formHasUpdated={formHasUpdated} handleAfterSave={handleAfterSave} handleSubmit={handleSubmit} handleError={handleError} />
                <NavButton to={'/sites'} copy="Cancel" />
                <SiteDeleteButton id={siteId} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export default EditSite;