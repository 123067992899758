import { useContext, useEffect, useState } from "react";
import ChangePostLayout from '../layouts/ChangePostLayout';
import useFetchNewLocalChanges from '../services/useFetchNewLocalChanges';
import { SocketContext } from '../contexts/SocketContext';

const DisplayNewChanges = () => {
  const { newChangeData, setNewChangeData, setNewPostCounter } = useContext(SocketContext);
  const [isPageVisible, setIsPageVisible] = useState(document.visibilityState === 'visible');
  const { data } = useFetchNewLocalChanges();

  const handleVisibilityChange = () => {
    setIsPageVisible(document.visibilityState === 'visible');
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    setNewChangeData([]);
  }, [data, setNewChangeData]);

  useEffect(() => {
    if (isPageVisible) {
      setNewPostCounter(0);
    }
  }, [newChangeData, setNewPostCounter, isPageVisible]);

  return (
    <>
      <div className="socket_latest_changes">
        <ChangePostLayout data={newChangeData} className="liveContent" />
      </div>
      <div className="database_latest_changes">
        <ChangePostLayout data={data} />
      </div>
    </>
  );
};

export default DisplayNewChanges;