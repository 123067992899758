import React, { useState, useEffect, useContext } from 'react';
import { PuffLoader } from 'react-spinners';
import { SocketContext } from '../contexts/SocketContext';
import { ReactSVG } from 'react-svg';

import SocketDisconnectedIcon from '../../assets/imgs/socket_disconnected_icon.svg';

function LiveIndicator() {
  const getTimeString = () => {
    const date = new Date();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${hours}:${minutes}:${seconds}`;
  };

  const [time, setTime] = useState(getTimeString());
  const { socketConnected } = useContext(SocketContext);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(getTimeString());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className='live_indicator'>
      <div className='live_time'>
        <p>{time}</p>
      </div>
      <div className='live_spinner'>
        {socketConnected ? (
          <PuffLoader color={'#948b3e'} size={30} />
        ) : (
          <div className='live_indicator_socket_disconnected_icon'>
            <ReactSVG src={SocketDisconnectedIcon} />
          </div>
        )}
      </div>
    </div>
  );
}

export default LiveIndicator;