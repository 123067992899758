import React from 'react';
import { ReactSVG } from 'react-svg';

import LogoutIcon from '../../../assets/imgs/logout_icon.svg';

const LogoutButton = ({ onLogout }) => {

  const handleLogOut = () => {
    if (onLogout) {
      onLogout();
    }
  };

  return (
    <button className='LogoutButton' onClick={handleLogOut}>
        <ReactSVG src={LogoutIcon} />
    </button>
  );
};

export default LogoutButton;