import React from 'react';

import main_logo from '../../../assets/imgs/Logo-Transparrent.png';

const LogoAndName = () => {
  return (
    <div className='logo_holder'>
        <img src={main_logo} className="main_logo" alt="logo" />
        <p>Watcher</p>
    </div>
  );
};

export default LogoAndName;
