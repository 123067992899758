import useAxios from "./useAxios";
import db from "./useDB";

class APIError extends Error {
  constructor(message, code) {
    super(message);
    this.name = "APIError";
    this.code = code;
  }
}

async function SaveSite(id = null, formData) {
  let api_command_url = '';

  if (id) {
    api_command_url = "/sites/update_site";
  } else {
    api_command_url = "/sites/add_site";
  }

  try {
    const response = await useAxios.put(api_command_url, {
      data: {
        formData: formData,
        site_id: id,
      },
    });

    if (response.data.success && response.data.site) {
      const siteId = response.data.site._id.valueOf();
      const updatedData = response.data.site;
      const existingSite = await db.sites.get(siteId);

      if (existingSite) {
        const updatedSite = {
          ...existingSite.value,
          ...updatedData,
        };

        await db.sites.put({
          id: siteId,
          value: updatedSite,
        });
      } else {
        await db.sites.put({
          id: siteId,
          value: updatedData,
        });
      }

      return updatedData;
    } else {
      return false;
    }
  } catch (err) {
    if (err.response.data.error_code === 10) {
      throw new APIError(
        "You were logged out because your session expired. Please log back in to continue.",
        10
      );
    } else if (err.response.data.error_code === 20) {
      throw new APIError(err.response.data.error_message, 20);
    }
  }
}

export default SaveSite;