import React from 'react';
import DisplaySites from '../services/DisplaySites';
import NavigationButton from '../elements/buttons/NavButton.js';

const Sites = () => {
    return (
        <section className='dashboard' id='dashboard__sites'>
            <div className='dashboard__inner'>
                <div className='dashboard__header'>
                    <div className='page_title'>
                        <h1>Watched Sites</h1>
                    </div>
                </div>
                <div className='dashboard__display_area'>
                    <DisplaySites />
                    <div className='table_tools_holder'>
                        <NavigationButton to="/sites/add" copy="Add Site" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Sites;