import React, {useContext, useEffect, useState, useCallback} from "react";
import { LogOutContext } from '../contexts/LogOutContext';
import SiteTableRowsLayout from "../layouts/SiteTableRowsLayout";
import useFetchAllSites from "./useFetchAllSites";
import { PropagateLoader } from 'react-spinners';

const DisplaySites = ({loading, sites, title}) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (!loading) {
      setShouldRender(true);
    }
  }, [loading]);

  return (
    <div className="site_table" id={"site_table__"+ title.toLowerCase().replace(/[^a-z0-9\s]/g, '').replace(/\s/g, '_')}>
      <div className='site_table__header'>
        <div className="table_columns">
          <div className="table_columns__titles">
            <div className="tc_title tc_title__url">
              <p>URL</p>
            </div>
            <div className="tc_title tc_title__type">
              <p>Check Type</p>
            </div>
            <div className="tc_title tc_title__frequency">
              <p>Check Frequency<br />(Seconds)</p>
            </div>
            <div className="tc_title tc_title__duration">
              <p>Check Duration</p>
            </div>
            <div className="tc_title tc_title__active">
              <p>Check Active</p>
            </div>
          </div>
          <div className="table_columns__tools_holder"></div>
        </div>
      </div>
      {loading ? (
        <div className="table_loading_area">
          <div className='spinner'>
            <PropagateLoader color={'#A18600'} size={16} loading={loading} />
          </div>
        </div>
      ) : (
        shouldRender && (
          <SiteTableRowsLayout title={title} sites={sites} />
        )
      )}
    </div>
  );
};

const DisplaySitesContainer = () => {
  const { LogOut } = useContext(LogOutContext);

  const stableLogOut = useCallback((message) => {
    LogOut(message);
  }, [LogOut]);

  const { loading, sites } = useFetchAllSites(stableLogOut);
  
  return <DisplaySites loading={loading} sites={sites} title="All Sites" />;
};

export default DisplaySitesContainer;
