import React, {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOutContext } from '../../contexts/LogOutContext';
import DeleteSite from '../../services/DeleteSite';

const SiteDeleteButton = ({ id }) => {
    const { LogOut } = useContext(LogOutContext);
    const [deleting, setDeleting] = useState(false);
    const navigate = useNavigate();

    const handleDeleteClick = async () => {
        setDeleting(true);
      
        if (window.confirm('Are you sure you want to delete this site?')) {
      
          try {
            const deletingSite = await DeleteSite(id);
      
            if (deletingSite) {
                setDeleting(false);
                navigate('/sites');
            }
          } catch (err) {
            if (err.code === 10) {
                setDeleting(false);
                LogOut(err.message);
            }
          }
        } else {
            setDeleting(false);
        }
    };
    

    return (
        <div className='btn btn_delete_site'>
            <button className='delete_button' onClick={handleDeleteClick} disabled={deleting}>
                <div className='button_inner'>
                    <p>{deleting ? "Deleting..." : "Delete"}</p>
                </div>
            </button>
        </div>
    )
}

export default SiteDeleteButton;