import { useState, useEffect } from "react";
import useAxios from './useAxios';
import db from "./useDB";

const useFetchMissingChanges = (LogOut) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchMissingChanges = async () => {
      const initLoad = sessionStorage.getItem('initChangesLoad');

      try {
        const storedData = await db.missing.toArray();

        if (storedData.length > 0 || initLoad === 'loaded') {
          const storedDataReturn = await storedData.map(item => item.value).reverse();

          setData(storedDataReturn);
        } else {
          const response = await useAxios.get("/changes/latest_unseen");
          if (response.data && Array.isArray(response.data) && response.data.length > 0) {
            const changesWithIdAsKey = response.data.map((change) => ({
              id: change._id.valueOf(),
              value: change,
              site_id: change.site_id
            }));
              
            await db.missing.bulkPut(changesWithIdAsKey);

            setData(response.data);
          }
          sessionStorage.setItem('initChangesLoad', 'loaded');
        }
      } catch (err) {
        if(err.response.data.error_code === 10) {
          LogOut("You were logged out because your session expired. Please log back in to continue.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchMissingChanges();
  }, [LogOut]);

  return { loading, data };
};

export default useFetchMissingChanges;
