import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/style/App.scss';
import Nav from './components/layouts/Nav';
import AppLayout from './components/layouts/AppLayout';
import DatabaseClearedContext from './components/contexts/DatabaseClearedContext';
import { SoundProvider } from './components/contexts/SoundContext';
import { SocketProvider } from './components/contexts/SocketContext';
import { LogOutProvider, LogOutContext } from './components/contexts/LogOutContext';
import db from "./components/services/useDB";
import Login from "./components/pages/Login";

const App = () => {
  const [isDatabaseCleared, setIsDatabaseCleared] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    (async () => {
      await db.missing.clear();
      await db.latest.clear();
      await db.sites.clear();
      setIsDatabaseCleared(true);
      sessionStorage.setItem('initChangesLoad', '');
      sessionStorage.setItem('initSitesLoad', '');
    })();

    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const MainContent = () => {
    const { LogOut } = useContext(LogOutContext);
    const [wrapperHeight, setWrapperHeight] = useState(window.innerHeight);

    const handleLogout = () => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      LogOut("Successfully logged out.");
    };

    const updateWrapperHeight = () => {
      setWrapperHeight(window.innerHeight);
    };

    useEffect(() => {
      window.addEventListener('resize', updateWrapperHeight);
      return () => {
        window.removeEventListener('resize', updateWrapperHeight);
      };
    }, []);

    return isDatabaseCleared ? (
      <DatabaseClearedContext.Provider value={{ isDatabaseCleared, setIsDatabaseCleared }}>
        <section className="app" style={{ height: wrapperHeight }}>
          {isLoggedIn ? (
            <>
              <Nav onLogout={handleLogout} />
              <AppLayout />
            </>
          ) : (
            <>
              <Login onLogin={handleLogin} />
            </>
          )}
        </section>
      </DatabaseClearedContext.Provider>
    ) : null;
  };

  return (
    <Router>
      <SoundProvider>
        <SocketProvider value={{ isLoggedIn }}>
          <LogOutProvider setIsLoggedIn={ setIsLoggedIn }>
            <MainContent />
          </LogOutProvider>
        </SocketProvider>
      </SoundProvider>
    </Router>
  );
};

export default App;