import React, { useState, useContext } from 'react';
import axios from 'axios';
import { LogOutContext } from '../../contexts/LogOutContext';

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { errorMessage, setErrorMessage } = useContext(LogOutContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    try {
      let API_URL = '';

      if (process.env.NODE_ENV === "production") {
        API_URL = '/user/login';
      } else {
        API_URL = window.location.protocol + "//" + window.location.hostname + (':' + 3000) + '/user/login';
      }

      const response = await axios.post(API_URL, {
        username,
        password,
        rememberMe,
      });

      if (response.data.success) {
        localStorage.setItem('token', response.data.token);

        if (onLogin) {
          onLogin();
        }
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }

    setIsSubmitting(false);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="on">
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <input
        name="email"
        type="email"
        placeholder="Username"
        value={username}
        onChange={(event) => setUsername(event.target.value)}
        disabled={isSubmitting}
        autoComplete="email"
      />
      <input
        name="current-password"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        disabled={isSubmitting}
        autoComplete="current-password"
      />
      <div className='trigger_input_holder'>
        <label className="custom-checkbox">
          Remember Me
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMeChange}
          />
          <span className="checkmark"></span>
        </label>
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Signing In...' : 'Login'}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
