import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import db from "../services/useDB";
import { SocketContext } from './SocketContext';

const LogOutContext = createContext({
  LogOut: () => {},
  errorMessage: '',
  setErrorMessage: () => {},
});

const LogOutProvider = ({ children, setIsLoggedIn }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { disconnectSocket, socketSaidLogOut, setSocketSaidLogOut } = useContext(SocketContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/');
      setShouldRedirect(false);
    }
  }, [shouldRedirect, navigate]);

  const LogOut = useCallback((message) => {
    db.missing.clear();
    db.latest.clear();
    db.sites.clear();
    disconnectSocket();
    localStorage.removeItem('token');
    sessionStorage.removeItem('initSitesLoad');
    sessionStorage.removeItem('initChangesLoad');
    setIsLoggedIn(false);
    setErrorMessage(message || '');
    setShouldRedirect(true);
    setSocketSaidLogOut(false);
  }, [disconnectSocket, setIsLoggedIn, setSocketSaidLogOut]);

  useEffect(() => {
    if(socketSaidLogOut) {
      LogOut(socketSaidLogOut);
    }
  }, [socketSaidLogOut, LogOut]);

  return (
    <LogOutContext.Provider value={{ LogOut, errorMessage, setErrorMessage }}>
      {children}
    </LogOutContext.Provider>
  );
};

export { LogOutContext, LogOutProvider };
