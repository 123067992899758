import { createContext, useState } from 'react';
import useSound from 'use-sound';
import notificationSFX from '../../assets/sounds/change_notification_sound.mp3';

const SoundContext = createContext();

const SoundProvider = ({ children }) => {
  const [toggled, setToggled] = useState(true);
  const [volume, setVolume] = useState(0);
  const [playNotification, { stop }] = useSound(notificationSFX, { volume });

  const toggleMute = () => {
    if (toggled) {
      setToggled(false);
      setVolume(1);
      playNotification();
    } else {
      setToggled(true);
      setVolume(0);
      stop();
    }
  };

  return (
    <SoundContext.Provider value={{ playNotification, toggleMute, volume, stop }}>
      {children}
    </SoundContext.Provider>
  );
};

export { SoundContext, SoundProvider };