import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import tools from '../services/tools';
import { ClockLoader } from 'react-spinners';
import DeleteSite from '../services/DeleteSite';
import { LogOutContext } from '../contexts/LogOutContext';

import DeleteButton from '../../assets/imgs/delete_site_icon.svg';
import EditButton from '../../assets/imgs/edit_site_icon.svg';

const SiteTableRowsLayout = ({ title, sites }) => {
  const [state, setState] = useState({
    deletingSiteId: null,
    deleting: false,
  });

  const [localSites, setLocalSites] = useState(sites);
  const { LogOut } = useContext(LogOutContext);

  const delete_site = async (site_id) => {
    setState((prevState) => ({ ...prevState, deleting: true }));
  
    if (window.confirm('Are you sure you want to delete this site?')) {
      setState((prevState) => ({ ...prevState, deletingSiteId: site_id }));
  
      try {
        const deletingSite = await DeleteSite(site_id);
  
        if (deletingSite) {
          setLocalSites((prevState) =>
            prevState.filter((site) => site._id.valueOf() !== site_id)
          );
          setState((prevState) => ({
            ...prevState,
            deletingSiteId: null,
            deleting: false,
          }));
        }
      } catch (err) {
        if (err.code === 10) {
          setState((prevState) => ({
            ...prevState,
            deletingSiteId: null,
            deleting: false,
          }));
          LogOut(err.message);
        }
      }
    } else {
      setState((prevState) => ({ ...prevState, deletingSiteId: null, deleting: false }));
    }
  };

  useEffect(() => {
    setLocalSites(sites);
  }, [sites]);
  

  return (
    <div className='site_table__body'>
        {localSites.length > 0 ? (
        <div className='site_table__inner'>
                <div className='table__rows'>
                    {localSites.map((site) => {
                        const site_url = tools.pretty_url(site.url);

                        let check_duration = '-';

                        if (site.check) {
                            check_duration = tools.duration_message(site);
                        }

                        const isDeleting = state.deletingSiteId === site._id.valueOf();
                        const rowClass = isDeleting ? 'row deleting' : 'row';

                        return (
                            <div className={rowClass} key={site._id.valueOf()} id={`row_${site._id.valueOf()}`}>
                                <div className='row_inner'>
                                    <Link to={"/sites/view/" + site._id.valueOf()}>
                                        <div className='column_rows'>
                                            <div className="tr_data tr_data__url">
                                                <p>{site_url}</p>
                                            </div>
                                            <div className="tr_data tr_data__type">
                                                <p>{site.type}</p>
                                            </div>
                                            <div className="tr_data tr_data__frequency">
                                                <p>{site.frequency}</p>
                                            </div>
                                            <div className="tr_data tr_data__duration">
                                                <p>{check_duration}</p>
                                            </div>
                                            <div className="tr_data tr_data__active">
                                                {site.check ? (
                                                    <p className='check_active'></p>
                                                ) : (
                                                    <p className='check_deactived'></p>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                    <div className='column_tools'>
                                        <div className='tool tool__edit'>
                                            <Link to={"/sites/edit/" + site._id.valueOf()}>
                                                <ReactSVG src={EditButton} />
                                            </Link>
                                        </div>
                                        <div className='tool tool__delete'>
                                            {state.deleting ? (
                                                <ClockLoader color={'#333'} size={15} loading={state.deleting}/>
                                            ) : (
                                                <button className='delete_button' onClick={() => delete_site(site._id.valueOf())} disabled={state.deleting}>
                                                    <ReactSVG src={DeleteButton} />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>                                
                        );
                    })}
                </div>
            </div>
        ) : (
            <div className='site_table__inner'>
                <div className='table__empty_message'>
                    <p>No sites are being watched. Click the 'Add Site' button to get started.</p>
                </div>
            </div>
        )}
    </div>
  );
};

export default SiteTableRowsLayout;
