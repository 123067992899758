import useAxios from "./useAxios";
import db from "./useDB";

class APIError extends Error {
  constructor(message, code) {
    super(message);
    this.name = "APIError";
    this.code = code;
  }
}

async function fetchSpecificChanges(change_id) {
  try {
    const response = await useAxios.put("/changes/get_change", {
      data: {
        change_id: change_id
      }
    });

    if (response.data) {
      const changeWithIdAsKey = {
        id: response.data._id.valueOf(),
        value: response.data,
        site_id: response.data.site_id
      };
    
      const exist_check = await db.latest.get(change_id);
      await db.latest.put(changeWithIdAsKey);
    
      if(exist_check === undefined) {
        return response.data;
      } else {
        return null;
      }
    }
    
    
  } catch (err) {
    if (err.response.data.error_code === 10) {
      throw new APIError("You were logged out because your session expired. Please log back in to continue.", 10);
    }
  }
  return null;
}

export default fetchSpecificChanges;