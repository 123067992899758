import React from 'react';
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <section className='dashboard' id='dashboard__not_found'>
            <div className='dashboard__inner'>
                <div className='dashboard__header'>
                    <div className='page_title'>
                        <h1>404 - Page Not Found</h1>
                        <p>The page you are looking for does not exist.</p>
                        <Link to="/">Return to the main page</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NotFound;