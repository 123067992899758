import axios from 'axios';

let API_URL = ""

if (process.env.NODE_ENV === "production") {
  API_URL = '/api';
} else {
  API_URL = window.location.protocol + "//" + window.location.hostname + (':' + 3000) + '/api';
}

const axiosInstance = axios.create({
  baseURL: API_URL
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;