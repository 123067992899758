class tools {
  static duration_message(site) {
    let check_duration = "-";

    if (site.check_range && site.check_range.from && site.check_range.until) {
      const timeNow = new Date();
      const timeFrom = new Date(site.check_range.from);
      const timeUntil = new Date(site.check_range.until);

      if (timeNow >= timeFrom && timeNow < timeUntil) {
        check_duration = (
          <>
            <span>From</span>
            {this.pretty_date(timeFrom)}
            <span>Until</span>
            {this.pretty_date(timeUntil)}
          </>
        );
      } else if (timeNow < timeFrom) {
        check_duration = (
          <>
            <span>Starting</span>
            {this.pretty_date(timeFrom)}
          </>
        );
      } else if (timeNow >= timeUntil) {
        check_duration = (
          <>
            <span>Stopped</span>
            {this.pretty_date(timeUntil)}
          </>
        );
      }
    } else if (site.check_range && site.check_range.from) {
      const timeNow = new Date();
      const timeFrom = new Date(site.check_range.from);

      if (timeNow >= timeFrom) {
        check_duration = (
          <>
            <span>From</span>
            {this.pretty_date(timeFrom)}
          </>
        );
      } else {
        check_duration = (
          <>
            <span>Starting</span>
            {this.pretty_date(timeFrom)}
          </>
        );
      }
    } else if (site.check_range && site.check_range.until) {
      const timeNow = new Date();
      const timeUntil = new Date(site.check_range.until);

      if (timeNow >= timeUntil) {
        check_duration = (
          <>
            <span>Stopped</span>
            {this.pretty_date(timeUntil)}
          </>
        );
      } else {
        check_duration = (
          <>
            <span>Until</span>
            {this.pretty_date(timeUntil)}
          </>
        );
      }
    } else {
      check_duration = '\u221E';
    }

    return check_duration;
  }

  static pretty_url(site_url) {
    const site_url_raw = new URL(site_url);
    const site_hostname = site_url_raw.hostname.replace(/^www\./, '');
    const site_pathname = site_url_raw.pathname;
    return site_hostname + site_pathname;
  }

  static pretty_domain(site_url) {
    const site_url_raw = new URL(site_url);
    const site_hostname = site_url_raw.hostname.replace(/^www\./, '');
    return site_hostname;
  }

  static pretty_date(dateObj) {
    const date = new Date(dateObj);
    const localDate = date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  
    return localDate;
  }

  static validateForm = (formData) => {
    const errors = {};

    const urlRegex = /^(?:(?:https?:\/\/)?(?:www\.)?)[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)+(\.[a-zA-Z0-9-_]{2,})?(\/[a-zA-Z0-9-_.]*)*(\?[a-zA-Z0-9-_&%=.]*)?(#.*)?$/;

    if (!formData.url) {
      errors.url = "URL is missing. This field can not be empty.";
    } else if (!urlRegex.test(formData.url)) {
      errors.url = 'Invalid URL. Please check the entered link.';
    }

    if (!formData.frequency || isNaN(formData.frequency) || parseFloat(formData.frequency) < 1) {
      errors.frequency = "Frequency must be a number greater than 1.";
    }

    if (formData.type === "custom") {
      if (!formData.snapshot.custom_container) {
        errors.custom_container = "Custom container field can not be empty.";
      }

      if (!formData.snapshot.custom_element) {
        errors.custom_element = "Custom element field can not be empty.";
      }

      if (!formData.snapshot.custom_element_title) {
        errors.custom_element_title = "Custom title field can not be empty.";
      }

      if (!formData.snapshot.custom_element_link) {
        errors.custom_element_link = "Custom link field can not be empty.";
      }
    }

    return {
      errors,
      isValid: Object.keys(errors).length === 0,
    };
  };

  static formatDateToInput(date) {
    if (!date) {
      return '';
    }
  
    const dateObj = new Date(date);
  
    if (isNaN(dateObj.getTime())) {
      return '';
    }
  
    const localDateString = dateObj.toLocaleString();
  
    const [yearMonthDay, hourMinute] = localDateString.split(', ');
    const [day, month, year] = yearMonthDay.split('/');

    const [hours, minutes] = hourMinute.split(':');
  
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;

    return formattedDate;
  }  
  
}
  
export default tools;