import React, { useContext } from 'react';
import { SocketContext } from '../contexts/SocketContext';

const NewPostCounter = () => {
    const { newPostCounter } = useContext(SocketContext);

    return (
        newPostCounter > 0 && (
            <div className='New_Post_Counter_Holder'>
                <p>{newPostCounter}</p>
            </div>
        )
    );
};

export default NewPostCounter;