import React from 'react';
import LoginForm from '../elements/forms/LoginForm';

import LogoAndName from '../elements/imgs/LogoAndName';

const Login = ({onLogin}) => {

  const handleLogin = () => {
    if (onLogin) {
      onLogin();
    }
  };

  return (
    <div className="login_page">
      <div className='login_page__inner'>
        <div className='login_page__form_holder'>
          <LogoAndName />
          <LoginForm onLogin={handleLogin} />
        </div>
      </div>
    </div>
  );
};

export default Login;